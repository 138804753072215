import React from "react";
import { graphql } from "gatsby";
import Image from "gatsby-image";
import ReactMarkdown from "react-markdown/with-html";
import { LinkRenderer } from "../../utils/utilities";

//Components
import HeroComponent from "../../components/panels/Hero";
import useSetPageData from "../../utils/useSetPageData";
import SEO from "../../components/seo";
import MoreCards from "../../components/panels/MoreCards";
import ComponentHeader from "../../components/panels/ComponentHeader";
import Approach from "../../components/panels/Approach";
import ListItem from "../../components/panels/ListItem";
import Collapsible from "../../components/panels/Collapsible";
import SwitchComponent from "../../components/panels/Switch";
import PanelFoundation from "../../components/panels/PanelFoundation";

//styles
import s from "./intelligent-automation.module.scss";

function IntelligentAutomation({ location, data }) {
  const {
    SEO: seo,
    Hero,
    overview,
    Cognitive,
    document_understanding_overview,
    document_understanding_cards
  } = data.strapiPsolIntelligentAutomation;

  useSetPageData(location);

  return (
    <>
      <SEO
        {...{
          title: `${Hero[0].main_heading} | Solutions`,
          description: seo.description
        }}
      />
      <HeroComponent
        // videoImage={Hero[0].services_main_image.childImageSharp.fixed.src}
        // videoLink={"/videos/final-cloud.mp4"}
        heading={Hero[0].main_heading}
        icon={Hero[0].service_icon.childImageSharp.fixed}
      />

      {/* <div style={{ marginTop: "40px" }}></div> */}
      <PanelFoundation rootClassName={`panel-padding`}>
        <div className={`col-sm-12`}>
          <ReactMarkdown
            source={overview}
            escapeHtml={false}
            renderers={{ link: LinkRenderer }}
          />
        </div>
      </PanelFoundation>
      <ComponentHeader
        rootClassName={"bg-light"}
        textClassName={"text-primary"}
        text={"Cognitive Assistants"}
        id={"rpa-cogn"}
      />

      <SwitchComponent
        oddClassName={`bg-primary text-white`}
        evenClassName={`bg-light text-primary`}
        contents={Cognitive.map(cog => {
          return {
            image: <Image fluid={cog.row_switch_image.childImageSharp.fluid} />,
            heading: cog.heading,
            content: (
              <ReactMarkdown
                source={cog.content}
                escapeHtml={false}
                renderers={{ link: LinkRenderer }}
              />
            )
          };
        })}
      />
      <ComponentHeader
        rootClassName={"bg-white"}
        textClassName={"text-primary"}
        text={"Document Understanding"}
        id={"rpa-doc"}
      />
      <SwitchComponent
        contents={document_understanding_overview.map(cog => {
          return {
            image: <Image fluid={cog.row_switch_image.childImageSharp.fluid} />,
            heading: cog.heading,
            content: (
              <ReactMarkdown
                source={cog.content}
                escapeHtml={false}
                renderers={{ link: LinkRenderer }}
              />
            )
          };
        })}
      />
      <ListItem
        columnsPerRow={3}
        isCard
        itemClassName={`${s.card}`}
        contents={document_understanding_cards.map((oc, i) => ({
          image: <Image fixed={oc.card_image.childImageSharp.fixed} />,
          heading: oc.heading,
          content: (
            <ReactMarkdown
              source={oc.content}
              escapeHtml={false}
              renderers={{ link: LinkRenderer }}
            />
          )
        }))}
      />
      <ComponentHeader
        rootClassName={`bg-light`}
        textClassName={`text-primary`}
        text={"Our Approach"}
      />
      <Approach />
      <ComponentHeader
        rootClassName={`bg-light`}
        textClassName={`text-primary`}
        text={"More Solutions"}
      />
      <MoreCards
        rootClassName={`bg-light`}
        itemClassName={`col-lg-4 col-md-6 `}
        cards={["ArtificialIntelligence", "RPA", "Chatbots"]}
      />
    </>
  );
}

export default IntelligentAutomation;

export const pageQuery = graphql`
  {
    strapiPsolIntelligentAutomation {
      SEO {
        description
      }
      Hero {
        main_heading
        services_main_image {
          childImageSharp {
            fixed(width: 250) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        service_icon {
          childImageSharp {
            fixed(width: 100, height: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
      overview
      Cognitive {
        heading
        content
        row_switch_image {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }

      document_understanding_overview {
        heading
        content
        row_switch_image {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }

      document_understanding_cards {
        heading
        content
        card_image {
          childImageSharp {
            fixed(width: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
  }
`;
